<template>
    <div class="consume-wrapper consumeDetailed" v-loading="loading">
        <div class="consume-box">
            <header>
                <ul>
                    <li>
                        <el-select v-model="formInline.station_id" placeholder="选择场站" @change="stationChange">
                            <el-option
                                v-for="(item,index) in stationData"
                                :key="index"
                                :label="item.name"
                                :value="item.station_id"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-select v-model="formInline.line_code" placeholder="选择生产机组" @change="lineChange">
                            <el-option value="" label="全部"></el-option>
                            <el-option
                                v-for="(item,index) in lineData"
                                :key="index"
                                :label="item.line_name"
                                :value="item.line_code"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-input v-model="formInline.strength_grade" placeholder="请输入强度等级"></el-input>
                    </li>
                    <li>
                        <el-date-picker
                            v-model="timeArray"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @change="timeChange"
                        >
                        </el-date-picker>
                    </li>
                    <li>
                        <el-input v-model="formInline.search" placeholder="请输入搜索内容...">
                            <template slot="append">
                                <button class="search-btn" @click="searchChange()">
                                    <i class="el-icon-search"></i>
                                </button>
                            </template>
                        </el-input>
                    </li>
                    <li>
                        <el-button type="primary" class="export_btn" @click="exportExcel">
                            <i class="iconfont icondaochu"></i>
                            <span>导出excel</span>
                        </el-button>
                    </li>
                </ul>
            </header>
            <section>
                <div class="table-wrapper table-bg" id="tableWrapper">
                    <el-table
                        id="tableData"
                        :data="tableData"
                        style="width: 100%;"
                        :header-row-style="{background: '#edf0f5',color: '#022782'}"
                        stripe
                        :summary-method="getSummaries"
                        show-summary
                        height="100%"
                        ref="productionConsumptionTable"
                    >
                        <el-table-column label="序号" type="index" :width="60"></el-table-column>
                        <el-table-column
                            v-for="(item,index) in tabelHeader"
                            :prop="item.field_name"
                            :label="item.field_desc"
                            :key="index"
                            :width="(index >= 9 && index <= 11) ? '210px': 'auto'"
                            show-overflow-tooltip
                        >
                            <template v-if="item.childs && item.childs.length > 0">
                                <el-table-column
                                    v-for="(jtem,jndex) in item.childs"
                                    :prop="jtem.field_name"
                                    :label="jtem.field_desc"
                                    :key="jndex"
                                    min-width="140"
                                    @click="tableListClick(jtem)"
                                >
                                </el-table-column>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import currencyFun from '@/assets/js/currencyFun';

export default {
    // 生产管理-报表统计-生产消耗汇总
    name: 'consume-detailed',
    data() {
        return {
            stationData: [], // 场站list
            strengthGradeData: [], // 产品标号
            lineData: [], // 生产机组
            timeArray: [], // 时间数组
            formInline: {
                station_id: '',
                line_code: '',
                strength_grade: '',
                search: '',
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    },
                }],
            },
            tableData: [],
            tabelHeader: [],
            loading: false,
            tableHeight: '',
            page: {
                pageindex: 0,
                pagesize: 10,
            },
            total: 100,
            sum_volume: 0,
        };
    },
    async created() {
        const newTimeNode = await currencyFun.getTimeNode('setting_product_time_format', this);
        if (Object.keys(newTimeNode).length === 0) {
            newTimeNode.start_time = '00:00:00';
            newTimeNode.end_time = '00:00:00';
        }
        this.timeArray = [
            util.timeFormat(new Date(new Date().getTime() - 3600 * 1000 * 24 * 1), 'yyyy-MM-dd') + ' ' + newTimeNode.start_time,
            util.timeFormat(new Date(new Date()), 'yyyy-MM-dd') + ' ' + newTimeNode.end_time,
        ];
    },
    mounted() {
        // 获取全部场站
        this.getStationData();
    },
    methods: {
        // 选择场站触发的事件
        stationChange() {
            this.lineData = [];
            this.formInline.line_code = '';
            this.gitlineData();
            this.getTableHeader();
            this.getTableData();
        },
        // 选择生产机组触发的事件
        lineChange() {
            this.getTableHeader();
            this.getTableData();
        },
        // 获取场站list
        getStationData() {
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid'))
                .then(res => {
                    this.stationData = res;
                    if (this.stationData.length > 0) {
                        this.formInline.station_id = this.stationData[0].station_id;
                        this.gitlineData();

                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产机组list
        gitlineData() {
            this.$axios
                .get(`/interfaceApi/production/mixstationline/${this.formInline.station_id}`)
                .then(res => {
                    this.lineData = res;
                    if (this.lineData.length > 0) {
                        // this.formInline.line_code = this.lineData[0].line_code;
                        this.getTableHeader();
                        this.getTableData();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产标号
        getstrengthGrade() {
            this.$axios
                .get('/interfaceApi/production/dictionary/dropdownlist/004-PH0/0')
                .then(res => {
                    this.strengthGradeData = res;
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 时间选择触发的事件
        timeChange() {
            this.getTableHeader();
            this.getTableData();
        },
        // 点击搜索按钮触发的事件
        searchChange() {
            this.getTableHeader();
            this.getTableData();
        },
        async getTableHeader() {
            if (this.timeArray.length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            this.$axios
                .post('/interfaceApi/production/productconsume/fields', this.formInline)
                .then(res => {
                    this.tabelHeader = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        async getTableData() {
            this.loading = true;
            if (this.timeArray.length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            this.$axios
                .post('/interfaceApi/production/productconsume/data', this.formInline)
                .then(res => {
                    this.loading = false;
                    this.tableData = res.result || [];
                    this.sum_volume = res.sum_volume || 0;
                    this.$nextTick(() => {
                        this.$refs.productionConsumptionTable.doLayout();
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getAutoHeight() {
            const el = document.querySelector('#tableWrapper');
            const elParent = el.parentNode;
            const pt = this.getStyle(elParent, 'paddingTop');
            const pb = this.getStyle(elParent, 'paddingBottom');
            this.$nextTick(() => {
                this.tableHeight = elParent.offsetHeight - (pt + pb) + 'px';
            });
        },
        getStyle(obj, attr) {
            // 兼容IE浏览器
            const result = obj.currentStyle
                ? obj.currentStyle[attr].replace('px', '')
                : document.defaultView
                    .getComputedStyle(obj, null)[attr].replace('px', '');
            return Number(result);
        },
        exportExcel() {
            this.loading = true;
            // 导出表格
            if (this.timeArray.length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            this.$axios
                .post('/interfaceApi/production/productconsume/exportexcel', this.formInline, { responseType: 'arraybuffer' })
                .then(res => {
                    this.loading = false;
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    const fileName = `生产消耗汇总${util.currentTimeFormat('yyyy-MM-dd HH:mm:ss')}.xls`;
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        handleSizeChange(val) {
            this.page.pagesize = val;
        },
        handleCurrentChange(val) {
            this.page.pageindex = val;
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if (index >= 1 && index <= 5) {
                    sums[index] = '';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                    sums[index] = sums[index].toFixed(2);
                } else {
                    sums[index] = '';
                }
            });
            return sums;

        },
    },
};
</script>

<style lang="stylus">
.consumeDetailed
    .consume-box
        width 100%
        height 100%
        background #ffffff
        display flex
        flex-direction column
        header
            height 0.5rem
            padding 0.1rem 0.1rem 0 0.1rem
            ul
                display flex
                height 100%
                li
                    margin-right 0.1rem
                    .el-date-editor
                        width 3.6rem !important
                        height 0.36rem
                    .el-input-group__append
                        background #1577d2
                        width 0.6rem
                        border none
                        padding 0
                        cursor pointer
                        .search-btn
                            border none
                            background #2978ff
                            width 0.6rem
                            cursor pointer
                            i
                                color #ffffff
                    .export_btn
                        border: none;
                        height: 0.36rem;
                        font-size: 0.14rem;
                        padding: 0 0.1rem;
                        min-width: 0.36rem;
                        line-height: 0.36rem;
                        cursor: pointer;
                        border-radius: 2px;
                        background: #f1f4f7;
                        color: #2978ff;
                        text-align: center;
        section
            flex 1
            padding 0.1rem
            overflow hidden
            .table-wrapper
                height 100%
                .el-table
                    display flex
                    flex-direction column
                    .el-table__header-wrapper
                        th
                            text-align center
                            background-color #edf0f5
                            padding 0
                            height 0.3rem
                    .el-table__body-wrapper
                        flex 1
                        .el-table__body
                            td
                                text-align center
                    .el-table__footer-wrapper
                        td
                            padding 0.06rem 0
                            text-align center
        .page-wrapper
            text-align right
            margin-top 0.1rem
            padding-bottom 0.1rem
</style>
